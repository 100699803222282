<div class="flex items-center mt-4" [style.justify-content]="justify">
  <div class="flex gap-x-2">
      <button aria-label="previousPageBtn" [style.visibility]="!length?'hidden':null" [disabled]="pageIndex == 1"
          mat-button (click)="goToPage(pageIndex - 1);control.setValue(pageIndex)">
          <i class="uil uil-angle-left px-2 py-1.5 btn-white border rounded-md"></i>
      </button>
      <div class="inline-block">
          <button mat-stroked-button *ngFor="let bt of buttons"
          (click)="bt!='...' && goToPage(+bt)" class="px-3.5 py-1 rounded-md mx-0.5"
          [ngClass]="+bt==pageIndex ? 'btn-dark-blue' : 'btn-white border' ">
          {{bt!='...'?(+bt):'...'}}
          </button>
      </div>
      <button aria-label="nextPageBtn" [style.visibility]="!length?'hidden':null" *ngIf="pageTotal > pageIndex + 1"
          mat-button (click)="goToPage(pageIndex + 1);control.setValue(pageIndex)"
          [disabled]="isPageLimit && pageIndex + 1 != 2">
          <i class="uil uil-angle-right px-2 py-1 btn-white border rounded-md"></i>
      </button>
      <button aria-label="nextPageBtn" [style.visibility]="!length?'hidden':null" *ngIf="pageTotal <= pageIndex + 1"
          mat-button (click)="goToPage(pageTotal)" [disabled]="pageIndex == pageTotal || isPageLimit">
          <i class="uil uil-angle-right px-2 py-1.5 btn-white border rounded-md"></i>
      </button>
  </div>
</div>
