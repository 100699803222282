import { Component, Input } from '@angular/core';
import { Button } from '@core/enums/button';

@Component({
  selector: 'sjc-youth-btn',
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss'
})
export class ButtonComponent {
  @Input() btnType: string = 'submit';
  @Input() btnClass: string = Button.DarkBlue;
  @Input() disabled: boolean = false;
}
