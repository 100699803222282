import { ReactiveFormsModule } from "@angular/forms"
import { HeaderComponent } from "./components/header/header.component"
import { FooterComponent } from "./components/footer/footer.component"
import { ButtonComponent } from "./components/button/button.component"
import { NavbarComponent } from "./components/navbar/navbar.component"
import { InputComponent } from "./components/input/input.component"
import { CardComponent } from "./components/card/card.component"
import { ToastComponent } from "./components/toast/toast.component"
import { CarouselComponent } from "./components/carousel/carousel.component"
import { MatSidenavModule } from '@angular/material/sidenav';
import { HeroComponent } from "./components/hero/hero.component"
import { PaginatorComponent } from "./components/paginator/paginator.component"
import { MatPaginatorModule } from "@angular/material/paginator"
import { PodcastModalComponent } from "./components/podcast-modal/podcast-modal.component"
import { CarouselModule } from "ngx-owl-carousel-o"
import { YouTubePlayerModule } from "@angular/youtube-player"

export const Components = [
  HeaderComponent,
  FooterComponent,
  ButtonComponent,
  NavbarComponent,
  InputComponent,
  CardComponent,
  ToastComponent,
  CarouselComponent,
  HeroComponent,
  PaginatorComponent,
  PodcastModalComponent
]

export const Modules = [
  ReactiveFormsModule,
  MatSidenavModule,
  MatPaginatorModule,
  CarouselModule,
  YouTubePlayerModule
]
