import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IPodcast } from '@core/interfaces/ipodcast';

@Component({
  selector: 'sjc-youth-podcast-modal',
  templateUrl: './podcast-modal.component.html',
  styleUrls: ['./podcast-modal.component.scss']
})
export class PodcastModalComponent implements OnChanges {
  @Input() podcasts: IPodcast[] = [];
  @Input() iframeSrc: string = '';
  @Input() podcast: IPodcast | any;
  @Output() onClose = new EventEmitter<void>();
  iframeCurrentSrc: SafeResourceUrl = '';

  constructor(private sanitizer: DomSanitizer) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['iframeSrc']) {  // Correctly accessing changes using bracket notation
      if (changes['iframeSrc'].currentValue !== changes['iframeSrc'].previousValue) {
        this.updateIframeSrc();
      }
    }
  }

  ngOnInit(): void {
    this.updateIframeSrc();
  }

  updateIframeSrc() {
    this.iframeCurrentSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeSrc || '');
  }

  playPodcast(podcast: IPodcast) {
    this.iframeSrc = podcast.website;
    this.updateIframeSrc();
  }

  close() {
    this.onClose.emit();
  }
}
