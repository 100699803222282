import { Component, Input, inject } from '@angular/core';
import { Router } from '@angular/router';
import { INavbarItems } from '@core/interfaces/inavbar-item';
import { take } from 'rxjs';

@Component({
  selector: 'sjc-youth-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent {
  private router = inject(Router);
  @Input() isMobile: boolean = false;
  navbarItems: INavbarItems[] = [];
  activeLink: string = '';

  ngOnInit(): void {
    this.setMenuItems();
    this.router.events
    .pipe(
      take(1)
    )
    .subscribe(() => {
      this.activeLink = this.isSpecifiedRoute() ? this.router.url : '';
    });
  }

  setMenuItems(): void {
    this.navbarItems = [
      { label: 'Crisis Situation', link: '/crisis-situation' },
      { label: 'About Us', link: '/about' },
      { label: 'Resources for family and caregivers', link: '/resources' },
      { label: 'Contact Us', link: '/contact' },
    ]
  }

  redirectToPreventionSite(): void {
    window.open('https://211sj.org/', '_blank');
  }

  isActive(link: string): boolean {
    return this.isSpecifiedRoute() && this.activeLink === link;
  }

  isSpecifiedRoute(): boolean {
    return this.navbarItems?.some(item => item.link === this.router.url);
  }
}
