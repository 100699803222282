<div class="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full z-50" id="podcast-modal" (click)="close()">
  <div class="relative top-20 mx-auto p-5 border w-10/12 shadow-lg rounded-md bg-[#3E5B6B] max-h-[85%] overflow-x-hidden" (click)="$event.stopPropagation()">
    <div class="mt-3 text-center">
      <iframe style="border-radius:8px;"
        [src]="iframeCurrentSrc"
        width="100%"
        height="500"
        frameBorder="0"
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"></iframe>
      <h3 class="text-lg leading-6 font-medium text-white text-left mt-6">More episodes</h3>
      <div class="mt-2 py-3">
        <ul>
          <li *ngFor="let podcast of podcasts" class="mb-4">
            <div style="border-radius:10px" class="px-5 py-3 bg-[#4F6D7D]">
              <div class="flex justify-between items-center pb-2 sm:pb-0">
                <span class="text-sm font-medium text-white text-left">{{ podcast.title }}</span>
                <button class="bg-transparent border-none p-0" (click)="playPodcast(podcast)">
                  <img src="assets/images/play-Icon.png" alt="Play Podcast" class="hover:opacity-75 h-5 sm:h-auto">
                </button>
              </div>
              <div class="text-xs text-white text-left">{{ podcast.description }}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
