<div *ngIf="cardClass !== Card.Podcast; else podcastTemplate"
  [ngClass]="cardClass"
  class="card h-full flex flex-col mx-auto mb-4">
  <div *ngIf="imageLink" class="img-content">
    <img class="w-full" [ngClass]="{ 'hidden' : !imageLink.length }"
    [src]="imageLink"
    [alt]="alternativeText"/>
  </div>
  <div *ngIf="!imageLink" class="no-img"></div>
  <div class="px-6 py-4 flex flex-col flex-grow">
    <h1 *ngIf="title" class="font-semibold text-left text-2xl md:text-base mb-1">{{ title }}</h1>
    <p *ngIf="secondaryDescription" class="text-left text-lg md:text-base mb-1">{{ secondaryDescription }}</p>
    <p *ngIf="additionalInformation" class="text-left text-dark-blue text-lg md:text-base mb-1">
      {{ additionalInformation }}
    </p>
    <p class="text-lg md:text-base text-left flex-grow">{{ description }}</p>
  </div>
  <div *ngIf="hasButton" class="px-6 pb-4 mt-auto mx-auto">
    <sjc-youth-btn
      [disabled]="!btnLink && cardClass != Card.Podcast"
      [btnClass]="btnClass"
      (click)="onClick()"
      class="w-full">
      {{ btnLink || cardClass == Card.Podcast ? btnLabel : 'No Website Available' }}
    </sjc-youth-btn>
  </div>
</div>

<ng-template #podcastTemplate>
  <div
    class="podcast-card"
    [ngClass]="{'blue-box': +additionalInformation % 2 === 0, 'yellow-box': +additionalInformation % 2 !== 0}">
    <div class="image-cont flex items-center">
      <iframe [src]="iframeCurrentSrc"
        frameBorder="0"
        [title]="title"
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy">
      </iframe>
    </div>
    <div class="card-text">
      <h2 class="text-[18px] font-bold m-0" *ngIf="title && additionalInformation">Ep.{{ additionalInformation }} {{ title }}</h2>
      <p class="mb-0">{{ description }}</p>
    </div>
  </div>
</ng-template>
