import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Card } from '@core/enums/card';
import { IPodcast } from '@core/interfaces/ipodcast';

@Component({
  selector: 'sjc-youth-card',
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss'
})
export class CardComponent {
  @Input() cardClass: string = 'card-white';
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() secondaryDescription: string = '';
  @Input() hasButton: boolean = false;
  @Input() btnClass: string = 'btn-blue';
  @Input() imageLink: string = '';
  @Input() btnLabel: string = 'EXPLORE'
  @Input() btnLink: string = ''
  @Input() additionalInformation: string = '';
  @Input() id: number = 0;
  @Input() iframeSrc: string = '';
  @Input() podcast: IPodcast | any;
  @Input() action: () => void = this.goToUrl;
  
  iframeCurrentSrc: SafeResourceUrl = '';
  alternativeText: string = '';
  Card = Card;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.alternativeText = 'image for ' + (this.title ? this.title : this.description);
    this.iframeSrcSanitize(this.iframeSrc);
  }

  iframeSrcSanitize(url: string): void {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    const match = url.match(regExp);

    this.iframeCurrentSrc = match && match[7].length == 11
      ? this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${match[7]}?si=Nqpnem6PAmmo2FbV&cc_load_policy=1`)
      : this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeSrc || '');
  }
  
  goToUrl(): void {
    if (!this.btnLink) return;
    var url = this.btnLink;
    if (!url.match(/^http?:\/\//i) && !url.match(/^https?:\/\//i)) {
      url = 'http://' + url;
    }

    window.open(url, "_blank");
  }

  onClick() {
    if (this.action) {
      this.action();
    } else {
      this.goToUrl();
    }
  }
}
