@switch (carouselType) {
  @case (CarouselType.Resource) {
    <owl-carousel-o [options]="customOptions">
      @for(r of resources; track r) {
        <ng-template carouselSlide>
          <sjc-youth-card
            [hasButton]="r.website !== null"
            [title]="r.title!"
            [description]="r.description"
            [imageLink]="r.imageLink"
            [btnLink]="r.website"
            [btnClass]="btnClass"
            [btnLabel]="btnLabel"
            [cardClass]="cardClass">
          </sjc-youth-card>
        </ng-template>
      }
    </owl-carousel-o>
  }
  @case (CarouselType.Quote) {
    <div class="mb-4">
      <h1 class="text-dark-grey font-bold text-[26px] text-center">
        Empowering Words
      </h1>
      <h2 class="text-dark-grey font-medium text-[16px] text-center">
        Illuminate Your Path with Inspiration Quotes
      </h2>
    </div>
    <owl-carousel-o
      [options]="customOptions" 
      (initialized)="setActiveSlide($event)" 
      (translated)="setActiveSlide($event)">
      @for (q of quotes; track q; let i = $index;) {
        <ng-template carouselSlide let-owlItem>
          <div [ngClass]="quoteClasses[i]"
            [@activeSlide]="owlItem.isCentered ? 'active' : 'inActive'" 
            class="slide mx-3 rounded-sm p-2 lg:py-3 lg:px-5">
            <div class="content flex flex-col justify-between h-full">
              <div class="text-left">
                <p>{{ q.quote }}</p>
              </div>
              <div class="text-right">
                <p class="text-[14px] font-bold leading-4">{{ q.author }}</p>
              </div>
            </div>
          </div>
        </ng-template>
      }
    </owl-carousel-o>
  }
  @case (CarouselType.Program) {
    <owl-carousel-o [options]="customOptions" (initialized)="setActiveSlide($event)">
      @for(r of resources; track r; let i = $index;) {
        <ng-template carouselSlide>
          <sjc-youth-card
            [hasButton]="r.website !== null"
            [title]="r.title!"
            [description]="r.description"
            [imageLink]="r.imageLink"
            [btnLink]="r.website"
            [btnClass]="btnClass"
            [btnLabel]="btnLabel"
            [cardClass]="programClasses[i]">
          </sjc-youth-card>
        </ng-template>
      }
    </owl-carousel-o>
  }
}
