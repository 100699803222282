import { Component, EventEmitter, Output, ViewChild, inject } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';

@Component({
  selector: 'sjc-youth-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  @Output() home = new EventEmitter();
  private router = inject(Router);
  mobileMenuOpen: boolean = false;

  @ViewChild('mobileNav') mobileNav!: MatSidenav;

  goHome(): void {
    this.router.navigateByUrl('');
    this.home.emit();
  }

  redirectToPreventionSite(): void {
    window.open('https://211sj.org/', '_blank');
  }

  toggleMobileMenu(): void {
    this.mobileMenuOpen = !this.mobileMenuOpen;
  }
}
