import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { CarouselType } from '@core/enums/carousel-type';
import { IResource } from '@core/interfaces/common/iresource';
import { IQuote } from '@core/interfaces/iquote';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';

@Component({
  selector: 'sjc-youth-carousel',
  templateUrl: './carousel.component.html',
  styleUrl: './carousel.component.scss',
  animations: [
    trigger('activeSlide', [
      state('active', style({
        opacity: 1,
        zIndex: 2,
      })),
      state('inActive', style({
        opacity: 1,
        zIndex: 1,
      })),
      transition('active => inActive', [
        animate('1s')
      ]),
      transition('inActive => active', [
        animate('1s')
      ])
    ])
  ]
})

export class CarouselComponent {
  @Input() resources?: IResource[] = [];
  @Input() itemWindow: number = 3;
  @Input() cardClass: string = '';
  @Input() btnLabel: string = '';
  @Input() btnClass: string = '';
  @Input() carouselType: CarouselType = CarouselType.Resource;
  @Input() quotes?: IQuote[] = [];

  activeQuote?: IQuote;
  activeIndex?: number = 0;
  CarouselType = CarouselType;
  displayGroup: IResource[] = [];
  start: number = 0;
  mid: number = 0;
  end: number = 0;
  current!: IResource;
  index: number = 0;
  activeSlides!: SlidesOutputData;
  quoteClasses: string[] = [];
  programClasses: string[] = [];

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 1000,
    dotsEach: true,
    center: true,
    autoplay: true,
    responsive: {
      0: {
        items: 1 
      },
      768: {
        items: 2 
      },
      1024: {
        items: 3 
      },
    },
  }
  
  setActiveSlide(event: SlidesOutputData) {
    this.activeSlides = event;
    this.activeIndex = event.startPosition;
    this.quoteClasses = this.quotes?.map((_, i) => this.getQuoteClass(i)) ?? [];
    this.programClasses = this.resources?.map((_, i) => this.getProgramClasses(i)) ?? [];
  }

  getProgramClasses(i: number): string {
    return `${this.cardClass} ${this.getColors(i)}`;
  }

  getQuoteClass(i: number): string {
    let quoteClass: string = `slide mx-3 rounded-sm ${this.getColors(i)}`;
    if (this.activeIndex === i) {
      quoteClass += ' active';
    } else if (this.quotes && this.activeIndex === this.quotes.length - 1 && i === 0) {
      quoteClass += ' right-of-active';
    } else if (this.activeIndex === i - 1) {
      quoteClass += ' right-of-active';
    } else if (this.activeIndex === 0 && this.quotes && i === this.quotes.length - 1) {
      quoteClass += ' left-of-active';
    } else if (this.activeIndex === i + 1) {
      quoteClass += ' left-of-active';
    } else {
      quoteClass += 'flex hidden';
    }

    return quoteClass;
  }

  getColors(i: number): string {
    let classes: string = '';
    if (this.carouselType === CarouselType.Quote) {
      switch(i % 3) {
        case 0:  classes += ` bg-salmon border border-dark-orange`; break;
        case 1:  classes += ` bg-light-blue border border-lilac`; break;
        case 2:  classes += ` bg-yellow border border-dark-yellow`; break;
        default: classes += ''; break;
      }
    } else {
      switch(i % 3) {
        case 0:  classes += ` bg-light-blue border border-lilac`; break;
        case 1:  classes += ` bg-white border border-grey`; break;
        case 2:  classes += ` bg-yellow border border-dark-yellow`; break;
        default: classes += ''; break;
      }
    }
    return classes;
  }
}
