<div class="w-screen lg:block lg:flex lg:h-full hidden">
  <div class="container mx-auto py-2">
    <div class="grid grid-cols-3 gap-4 items-center">
      <div class="col-span-1 flex items-center">
        <img src="assets/images/YouthWellness-Logo-Ideas.png" (click)="goHome()" class="cursor-pointer" alt="SJC Youth Logo" width="200"/>
      </div>
      <div class="col-span-2 flex flex-row justify-end gap-x-10">
        <sjc-youth-btn (click)="redirectToPreventionSite()">
          <span class="font-medium">
            San Joaquin 211
          </span>
        </sjc-youth-btn>
        <sjc-youth-navbar></sjc-youth-navbar>
      </div>
    </div>
  </div>
</div>

<div class="lg:hidden flex items-center justify-between bg-menuPurple px-5 w-[100%]">
  <img src="assets/images/YouthWellness-Logo-Ideas.png" (click)="goHome()" class="cursor-pointer" alt="SJC Youth Logo" width="200"/>
  <div class="mobile-nav-container">
    <button
      aria-label="Open Side Navbar"
      mat-icon-button
      (click)="mobileNav.toggle()">
      <mat-icon>
        <i class="text-4xl uil uil-bars cursor-pointer no-print"></i>
      </mat-icon>
    </button>
    <mat-sidenav-container>
      <mat-sidenav #mobileNav
        fixedInViewport="true"
        position="end"
        class="mobile-nav-position z-100">
        <mat-nav-list>
          <sjc-youth-navbar
            (click)="mobileNav.close()"
            [isMobile]="true">
          </sjc-youth-navbar>
        </mat-nav-list>
      </mat-sidenav>
    </mat-sidenav-container>
  </div>
</div>
