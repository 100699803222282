<div class="flex flex-row justify-between z-10">
    <div class="flex flex-row">
      <i class="text-[22px]" [ngClass]="icon"></i>
      <div class="flex flex-col">
        <span class="text-lg text-white" *ngIf="headerTxt">{{headerTxt}}</span>
        <span [ngClass]="headerTxt ? 'text-sm text-white' : 'text-lg text-white'"> {{message}}</span>
      </div>
    </div>
    <div class="flex items-center">
      <i class="text-[22px] uil uil-multiply text-white" (click)="snackBarRef.dismissWithAction()"></i>
    </div>
  </div>
  