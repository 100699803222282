import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms'
import { Components, Modules } from './module-helper';
import { PodcastModalComponent } from './components/podcast-modal/podcast-modal.component';

@NgModule({
  declarations: [
    Components,
    PodcastModalComponent
  ],
  imports: [
    Modules,
    CommonModule,
    RouterModule,
    FormsModule
  ],
  exports: [
    Components,
    Modules,
    CommonModule,
    RouterModule,
    FormsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedModule { }
