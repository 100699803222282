import { Component } from '@angular/core';

@Component({
  selector: 'sjc-youth-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {

}
