export enum Button {
  DarkBlue = 'btn-dark-blue',
  Salmon = 'btn-salmon',
  White = 'btn-white',
  WhiteStrong = 'btn-white-strong',
  Yellow = 'btn-yellow',
  HelpYellow = 'btn-help-yellow',
  YellowBlue = 'btn-yellow-blue',
  Link = 'btn-link',
  Underline = 'btn-underline',
  Outline = 'btn-outline',
}
