<div class="flex flex-col text-base">
  @if (label && label.length > 0) {
    <label [ngClass]="{ 'required' : required }"
      [class.pl-1]="true"
      [class]="labelColor"
      class="text-dark-grey"
      [for]="labelId">
      {{ label }}
    </label>
  }
  @switch (inputType) {
    @case (InputType.Text) {
      <div class="col-span-2 relative">
        <div class="flex flex-row items-center">
          <input type="text"
                [id]="labelId"
                [maxLength]="maxLength"
                [placeholder]="placeHolder"
                [attr.aria-label]="ariaLabel"
                [readonly]="readOnly"
                [formControl]="control"
                [ngClass]="{ 'py-4': hasSearch, 'border-red': control.invalid && (control.dirty || control.touched) }"
                class="w-full px-4 py-2 border border-dark-blue rounded-lg text-black">
          @if (hasSearch) {
            <i class="uil uil-search absolute text-dark-blue right-0 mr-3 text-xl cursor-pointer" (click)="searchInput(control.value)"></i>
          }
        </div>
      </div>
      @if (control.invalid  && (control.dirty || control.touched)) {
        <p class="text-sm text-red">{{label}} is invalid.</p>
      }
    }
    @case (InputType.Number) {
      <div class="col-span-2 relative">
        <div class="flex flex-row items-center">
          <input type="number"
                [placeholder]="placeHolder"
                [attr.aria-label]="label"
                [formControl]="control"
                [readonly]="readOnly"
                min="0"
                class="w-full px-4 py-2 border border-dark-blue rounded-lg"
                [ngClass]="{ 'border-red': control.invalid && (control.dirty || control.touched) }">
          @if (hasSearch) {
            <i class="uil uil-search absolute text-dark-blue right-0 mr-3 text-xl cursor-pointer" (click)="searchInput(control.value)"></i>
          }
        </div>
      </div>
      @if (control.invalid  && (control.dirty || control.touched)) {
        <p class="text-sm text-red">{{label}} is invalid.</p>
      }
    }
    @case (InputType.Email) {
      <input
        [placeholder]="placeHolder"
        [id]="labelId"
        [maxLength]="maxLength"
        [attr.aria-label]="label"
        type="email"
        email="true"
        [readonly]="readOnly"
        [formControl]="control"
        class="w-full px-4 py-2 border border-dark-blue rounded-lg"
        [ngClass]="{ 'border-red': control?.invalid && (control?.dirty || control?.touched) }">
    @if (control?.invalid  && (control?.dirty || control?.touched)) {
      <p class="text-sm text-red">Email address is invalid.</p>
      }
    }
    @case (InputType.Password) {
      <input
        [placeholder]="placeHolder"
        [id]="label"
        [attr.aria-label]="label"
        type="password"
        [formControl]="control"
        class="w-full px-4 py-2 border border-dark-blue rounded-lg"
        [ngClass]="{ 'border-red': control.invalid && (control.dirty || control.touched) }">
    @if (control.invalid  && (control.dirty || control.touched)) {
      <p class="text-sm text-red">Password is required.</p>
      }
    }
    @case (InputType.Textarea) {
      <textarea
        [placeholder]="placeHolder"
        [id]="labelId"
        [maxLength]="maxLength"
        [attr.aria-label]="label"
        [formControl]="control"
        rows="6"
        class="w-full px-4 py-2 border border-dark-blue rounded-lg"
        [ngClass]="{ 'border-red': control.invalid && (control.dirty || control.touched) }"></textarea>
      @if (control.invalid  && (control.dirty || control.touched)) {
          <p class="text-sm text-red">{{label}} is invalid.</p>
        }
    }
    @case (InputType.File) {
      <div class="grid grid-cols-2 gap-x-4">
        <input type="text"
        [placeholder]="placeHolder"
        [attr.aria-label]="label"
        [formControl]="control"
        [readonly]="readOnly"
        class="w-full px-4 py-2 border border-dark-blue rounded-lg"
        [ngClass]="{ 'border-red': control.invalid && (control.dirty || control.touched) }">
        <div class="pt-2">
          <input
            type="file"
            name="file"
            id="file-input"
            (change)="file($event)"
            [attr.aria-label]="label"
            accept="image/*"
            hidden
            [ngClass]="{ 'border-red': control.invalid && (control.dirty || control.touched) }"/>
          <label class="btn-blue p-3 px-8 font-normal cursor-pointer" for="file-input">Browse</label>
        </div>
      </div>
      @if (control.invalid  && (control.dirty || control.touched)) {
          <p class="text-sm text-red">{{label}} is invalid.</p>
        }
    }
  }
</div>
