import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { InputType } from '@core/enums/input-type';

@Component({
  selector: 'sjc-youth-input',
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss'
})
export class InputComponent {
  @Output() search = new EventEmitter();
  @Output() fileChange = new EventEmitter();
  @Input() inputType: string = 'text';
  @Input() control!: FormControl | any;
  @Input() label: string = '';
  @Input() ariaLabel: string = '';
  @Input() required: boolean = false;
  @Input() placeHolder: string = '';
  @Input() hasSearch: boolean = false;
  @Input() readOnly: boolean = false;
  @Input() labelColor: string = 'text-black';
  @Input() labelId: string = '';
  @Input() maxLength: number = 1000;
  InputType = InputType;

  ngOnInit(): void {
    if (this.label != '') {
      this.labelId = this.label.replace(/\s/g, "") + new Date().getMilliseconds();
    } else if (this.ariaLabel != '') {
      this.labelId = this.ariaLabel.replace(/\s/g, "") + new Date().getMilliseconds();
    }
  }

  searchInput(value: string): void {
    this.search.emit(value);
  }

  file(file: any): void {
    this.fileChange.emit(file);
  }
}
